import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components'
import {StaticQuery, graphql, Link} from 'gatsby'
import Img from "gatsby-image"

const ImageContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 1em;
`
const RoofImage = styled(Img)`
  width: 100%
`
const Copy = styled.div`
  padding: 1.5rem;
`

const CommercialPage = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          bar: file(relativePath: { eq: "safetyworker.png" }) {
            childImageSharp {
              fluid(quality: 20, maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <Layout>
          <SEO title="Commercial" />
          
          <ImageContainer>
            <RoofImage fluid={data.bar.childImageSharp.fluid}></RoofImage>
          </ImageContainer>

          <Copy>
            If you're in need of commercial roofing services, give us the opportunity to asses your needs and provide a tailored solution and quote for your business, big or small. We've mastered all types of commercial roofing installations, from coal tar, asphalt, and gravel to modified bitumen and more. We procure high-quality materials to get the job done for any budget.           <br/>
            <br/>
            To request a free quote, fill out the form to the right or <Link to='/contactus'>contact us</Link> to have one of our staff members call or email you at your convenience.          
          </Copy>

        </Layout>
      )}
    />
  )
}

export default CommercialPage
